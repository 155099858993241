const routes = [
  {
    path: "/",
    component: () => import("layouts/Main.vue"),
    children: [
      { path: "", component: () => import("pages/main/Accounts.vue") },
    ],
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/app/:accountId",
    name: "base",
    component: () => import("layouts/App.vue"),
    children: [
      {
        path: "marketing",
        component: () => import("pages/app/marketing/Logs.vue"),
      },
      {
        path: "marketing/customers",
        component: () => import("pages/app/marketing/Customers.vue"),
      },
      {
        path: "marketing/settings",
        component: () => import("pages/app/marketing/Settings.vue"),
      },
      {
        path: "marketing/templates",
        component: () => import("pages/app/marketing/Templates.vue"),
      },
      {
        path: "marketing/forms",
        component: () => import("pages/app/marketing/Forms.vue"),
      },
      {
        path: "marketing/letters",
        component: () => import("pages/app/marketing/Letters.vue"),
      },
      {
        path: "marketing/contacts",
        component: () => import("pages/app/marketing/Contacts.vue"),
      },
      {
        path: "marketing/services",
        component: () => import("pages/app/marketing/Services.vue"),
      },
      {
        path: "marketing/senders",
        component: () => import("pages/app/marketing/Senders.vue"),
      },
      {
        path: "orders",
        component: () => import("pages/app/orders/Orders.vue"),
      },
      {
        path: "orders/comments",
        component: () => import("pages/app/orders/Comments.vue"),
      },
      {
        path: "orders/settings",
        component: () => import("pages/app/orders/Settings.vue"),
      },
      {
        path: "issues",
        component: () => import("pages/app/issues/Issues.vue"),
      },
      {
        path: "issues/settings",
        component: () => import("pages/app/issues/Settings.vue"),
      },
      {
        path: "distribution",
        component: () => import("pages/app/distribution/Distributors.vue"),
      },
      {
        path: "distribution/settings",
        component: () => import("pages/app/distribution/Settings.vue"),
      },
      {
        path: "questionnaires",
        component: () => import("pages/app/questionnaires/Questionnaires.vue"),
      },
      {
        path: "questionnaires/settings",
        component: () => import("pages/app/questionnaires/Settings.vue"),
      },
      {
        path: "trackers",
        component: () => import("pages/app/trackers/Trackers.vue"),
      },
      {
        path: "trackers/templates",
        component: () => import("pages/app/trackers/Templates.vue"),
      },
      {
        path: "trackers/technicians",
        component: () => import("pages/app/trackers/Technicians.vue"),
      },
      {
        path: "trackers/settings",
        component: () => import("pages/app/trackers/Settings.vue"),
      },
      {
        path: "commissions",
        component: () => import("pages/app/commissions/Commissions.vue"),
      },
      {
        path: "commissions/partners",
        component: () => import("pages/app/commissions/Users.vue"),
      },
      {
        path: "commissions/settings",
        component: () => import("pages/app/commissions/Settings.vue"),
      },
      {
        path: "requests",
        component: () => import("pages/app/requests/Requests.vue"),
      },
      {
        path: "requests/clients",
        component: () => import("pages/app/requests/Clients.vue"),
      },
      {
        path: "requests/settings",
        component: () => import("pages/app/requests/Settings.vue"),
      },
      {
        path: "sales",
        component: () => import("pages/app/sales/Clients.vue"),
      },
      {
        path: "sales/suppliers",
        component: () => import("pages/app/sales/Suppliers.vue"),
      },
      {
        path: "sales/projects",
        component: () => import("pages/app/sales/Projects.vue"),
      },
      {
        path: "sales/quotations",
        component: () => import("pages/app/sales/Quotations.vue"),
      },
      {
        path: "sales/products",
        component: () => import("pages/app/sales/Products.vue"),
      },
      {
        path: "sales/settings",
        component: () => import("pages/app/sales/Settings.vue"),
      },
      {
        path: "users",
        component: () => import("pages/app/general/Users.vue"),
      },
      {
        path: "roles",
        component: () => import("pages/app/general/Roles.vue"),
      },
      {
        path: "chats",
        component: () => import("pages/app/general/Chats.vue"),
      },
      {
        path: "settings",
        component: () => import("pages/app/general/Settings.vue"),
      },
    ],
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/auth",
    component: () => import("layouts/Auth.vue"),
    children: [
      { path: "login", component: () => import("pages/auth/Login.vue") },
      { path: "register", component: () => import("pages/auth/Register.vue") },
    ],
  },

  // Always leave this as last one,
  // but you can also remove it
  {
    path: "/:catchAll(.*)*",
    component: () => import("pages/Error.vue"),
  },
];

export default routes;
